const generateHandle = (name: string | undefined) => {
  let handle = name
  try {
    if (handle) {
      handle = handle.replace(/\W+(?!$)/g, "-").toLowerCase()
      handle = handle.replace(/\W$/, "").toLowerCase()
    }
  } catch (e) {
    console.error("Could not create handle for:", handle)
  }
  return handle
}
export default generateHandle

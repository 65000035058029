import React, { createContext, useState } from "react"
import { Amplify, API, Auth } from "aws-amplify"
import awsconfig from "../aws-exports"
import { GraphQLOptions } from "@aws-amplify/api-graphql"
import { getTeam } from "../graphql/queries"
import { Team } from "../constants/Team"

Amplify.configure(awsconfig)

interface TeamState {
  team: Team | undefined
  teamList: Team[] | undefined
  getTeamInfo: (teamID: string) => Promise<Team | undefined>
  getTeamList: (teamIDs: string[]) => Promise<void>
}

interface TeamContextProps {
  children: React.ReactNode
}

export const TeamContext = createContext<TeamState>({
  team: undefined,
  teamList: undefined,
  getTeamInfo: async () => undefined,
  getTeamList: async () => undefined,
})

const TeamContextProvider = ({ children }: TeamContextProps) => {
  const [team, setTeam] = useState<Team>()
  const [teamList, setTeamList] = useState<Team[]>()

  const getTeamList = async (teamIDs: string[]) => {
    let authMode: GraphQLOptions["authMode"]
    let teamArray: Team[] = []
    console.log("getTeamList", teamIDs)
    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    for (const teamID of teamIDs) {
      try {
        const getQuery = {
          query: getTeam,
          variables: {
            id: teamID,
          },
          authMode: authMode,
        }
        const query: any = await API.graphql(getQuery)
        const teamRecord = query?.data?.getTeam
        if (teamRecord) {
          teamArray.push(teamRecord)
        }
      } catch (err) {
        console.log("ERROR getting teamList", err)
      }
    }
    setTeamList(teamArray)
  }

  const getTeamInfo = async (teamID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const getQuery = {
        query: getTeam,
        variables: {
          id: teamID,
        },
        authMode: authMode,
      }
      const query: any = await API.graphql(getQuery)
      const teamRecord = query?.data?.getTeam
      if (teamRecord) {
        setTeam(teamRecord)
      }
      return teamRecord
    } catch (err) {}
    return undefined
  }

  return (
    <TeamContext.Provider
      value={{
        team,
        teamList,
        getTeamInfo,
        getTeamList,
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}

export default TeamContextProvider

import { LocationRestriction, Location } from "./Location"

export interface Team {
  id: string
  name?: string
  email?: string
  handle?: string
  locations?: Location[]
  locationRestrictions?: [LocationRestriction]
  teammateIDs: string[]
  teammates?: [Teammate]
  organizationID: string
  baseType: string
  metrics?: any
  createdAt?: string
  updatedAt?: string
}

export interface TeammateRoleMap {
  organizationID: string
  roles: TeammateRoleType[]
}

export interface Teammate {
  id: string
  name?: string
  email: string
  handle?: string
  locations?: Location[]
  locationRestrictions?: [LocationRestriction]
  type?: TeammateRoleType
  roles: TeammateRoleMap[]
  picture?: string
  organizationIDs: string[]
  googleCalendarConfig?: any
  baseType: string
  metrics?: any
  createdAt?: string
  updatedAt?: string
}

export enum TeammateRoleType {
  ADMIN = "ADMIN",
  STAFF = "STAFF",
  OTHER = "OTHER",
}

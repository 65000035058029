exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-component-catalog-tsx": () => import("./../../../src/pages/component/catalog.tsx" /* webpackChunkName: "component---src-pages-component-catalog-tsx" */),
  "component---src-pages-component-orders-tsx": () => import("./../../../src/pages/component/orders.tsx" /* webpackChunkName: "component---src-pages-component-orders-tsx" */),
  "component---src-pages-component-sign-in-tsx": () => import("./../../../src/pages/component/sign-in.tsx" /* webpackChunkName: "component---src-pages-component-sign-in-tsx" */),
  "component---src-pages-component-sign-up-tsx": () => import("./../../../src/pages/component/sign-up.tsx" /* webpackChunkName: "component---src-pages-component-sign-up-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-account-tsx": () => import("./../../../src/pages/my-account.tsx" /* webpackChunkName: "component---src-pages-my-account-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-profile-edit-tsx": () => import("./../../../src/pages/profile-edit.tsx" /* webpackChunkName: "component---src-pages-profile-edit-tsx" */),
  "component---src-pages-recommendation-tsx": () => import("./../../../src/pages/recommendation.tsx" /* webpackChunkName: "component---src-pages-recommendation-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-affiliate-product-tsx": () => import("./../../../src/templates/affiliate-product.tsx" /* webpackChunkName: "component---src-templates-affiliate-product-tsx" */),
  "component---src-templates-component-affiliate-product-tsx": () => import("./../../../src/templates/component-affiliate-product.tsx" /* webpackChunkName: "component---src-templates-component-affiliate-product-tsx" */)
}


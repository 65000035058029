import React, { createContext, useState } from "react"
import { Amplify, API, Auth } from "aws-amplify"
import awsconfig from "../aws-exports"
import { GraphQLOptions } from "@aws-amplify/api-graphql"
import { contentByOrganization } from "../graphql/queries"
import { LibraryContent } from "../constants/Library"

Amplify.configure(awsconfig)

interface LibraryState {
  getLibraryList: () => Promise<LibraryContent[]>
  libraryContent: LibraryContent[] | undefined
}

interface LibraryContextProps {
  children: React.ReactNode
}

export const LibraryContext = createContext<LibraryState>({
  getLibraryList: async () => [],
  libraryContent: [],
})

const LibraryContextProvider = ({ children }: LibraryContextProps) => {
  const [libraryContent, setLibraryContent] = useState<LibraryContent[]>()

  const getLibraryList = async () => {
    let authMode: GraphQLOptions["authMode"]
    let libraryArray: LibraryContent[] = []

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      let items: LibraryContent[] = []

      const query: any = await API.graphql({
        query: contentByOrganization,
        variables: {
          organizationID: `ffcb4cc9-1e5a-4889-a70d-783f6619227d`,
        },
        authMode: authMode,
      })

      items = query?.data?.contentByOrganization?.items // console.log(
      //   "process.env.TEMPLATE_OWNER",
      //   `ffcb4cc9-1e5a-4889-a70d-783f6619227d`
      // )
      // console.log("contentList Items query", query)
      // console.log("contentList Items", items)

      setLibraryContent(items)
      // console.log("libraryContent", libraryContent)
      return items
    } catch (err) {
      // console.log("library error", JSON.stringify(err))
    }
    // console.log("libraryContent", libraryArray)
    return libraryArray
  }

  return (
    <LibraryContext.Provider
      value={{
        getLibraryList,
        libraryContent,
      }}
    >
      {children}
    </LibraryContext.Provider>
  )
}

export default LibraryContextProvider

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://2bsswpdsgbdglkswisu4wazrla.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-n7vghxjmn5bahj4dagoouju46q",
    "aws_cloud_logic_custom": [
        {
            "name": "azingorest",
            "endpoint": "https://ebu657iw58.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:735f1f3d-646d-478f-a03d-8e73bcfc6d8d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Or2lXeRNa",
    "aws_user_pools_web_client_id": "beahvaeoq6cpc3vaqdlroa7bc",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "azingostorage154225-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import WebContextProvider from './src/contexts/content-context'
import BookingContextProvider from './src/contexts/booking-context'
import ServiceContextProvider from './src/contexts/service-context'
import StripeContextProvider from './src/contexts/stripe-context'
import AccountContextProvider from './src/contexts/account-context'
import AuthContextProvider from './src/contexts/auth-context'
import CartContextProvider from './src/contexts/cart-context'
import TeamContextProvider from './src/contexts/team-context'
import LibraryContextProvider from './src/contexts/library-context'
import { CookiesProvider } from 'react-cookie'
import "./src/styles/global.css"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#fff",
      },
    },
  })

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#000",
      },
    },
  })
export const wrapRootElement = ({ element }) => (
    <ThemeProvider  theme={`${process.env.THEME}` === "dark" ? darkTheme : lightTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CookiesProvider>
                <AuthContextProvider>
                    <WebContextProvider>
                        <TeamContextProvider>
                            <ServiceContextProvider>
                                <BookingContextProvider>
                                    <AccountContextProvider>    
                                        <StripeContextProvider>
                                            <CartContextProvider>
                                                <LibraryContextProvider>
                                                    {element}
                                                </LibraryContextProvider>
                                            </CartContextProvider>
                                        </StripeContextProvider>    
                                    </AccountContextProvider>
                                </BookingContextProvider>
                            </ServiceContextProvider>
                        </TeamContextProvider>
                    </WebContextProvider>
                </AuthContextProvider>
            </CookiesProvider>
        </LocalizationProvider>
    </ThemeProvider>
)
import { ServiceLocationType, LocationRestriction, Location } from "./Location"
import { ScheduledServiceTypeEnum } from "./TimeSlot"

export interface Service {
  id: string
  active: boolean
  affiliate: boolean
  allowBookableTimeSelection?: boolean
  autoAssignTeam?: boolean
  billingType: BillingTypeEnum
  billingFrequency?: FrequencyEnum
  bookingBufferBefore?: number
  bookingBufferAfter?: number
  bookingCountMax?: number
  bookingRestrictions?: ServiceBookingRestrictions
  bookingType: BookingTypeEnum
  categoryIDs?: string[]
  contentFrequency?: number
  cost?: number
  deliveryType: ScheduledServiceTypeEnum
  deliveryOptions: [OnlineDelivery]
  defaultDuration: number
  description?: string
  displayOrder?: string
  featured?: boolean
  formattedDescription?: string
  handle: string
  images?: string[]
  isDeleted?: boolean
  languageCode?: string
  linked: boolean
  locationType?: ServiceLocationType
  locations?: Location[]
  locationRestrictions?: [LocationRestriction]
  maxAttendees?: number
  metrics?: any
  name: string
  nextStepURL?: string
  organizationID: string
  prices: { items: Price[] }
  prohibitedSubscriptions?: string[]
  registrationType: ServiceRegistrationType
  requiredSubscriptions?: string[]
  stripePrices?: StripePrice[]
  tagline?: string
  teamIDs?: string[]
  thumbnail: string
  type: string
  baseType: string
  createdAt?: string
  updatedAt?: string
}

export enum OnlinePlatform {
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  TWITCH = "TWITCH",
  YOUTUBE = "YOUTUBE",
  VIDEOCONFERENCE = "VIDEOCONFERENCE",
}

export interface OnlineDelivery {
  platform: OnlinePlatform
  url?: string
  customerMessage?: string
}

export enum ServiceRegistrationType {
  ALLSESSIONS = "ALLSESSIONS",
  SELECTSESSIONS = "SELECTSESSIONS",
}

export enum BookingTypeEnum {
  BOOKABLE = "BOOKABLE",
  SCHEDULED = "SCHEDULED",
  PRODUCT = "PRODUCT",
}

export const BookingType = {
  BOOKABLE: "Bookable",
  SCHEDULED: "Scheduled",
  STANDARD: "Standard",
  SUBSCRIPTION: "Subscription",
  INTERNAL: "Internal",
  CUSTOM: "Custom",
}

export enum BillingTypeEnum {
  SUBSCRIPTION = "SUBSCRIPTION",
  PERSESSION = "PERSESSION",
  INADVANCE = "INADVANCE",
  NOCHARGE = "NOCHARGE",
  ONETIME = "ONETIME",
}

export const BillingType = {
  SUBSCRIPTION: "Subscription",
  PERSESSION: "Pay per session",
  INADVANCE: "Pay in advance",
  NOCHARGE: "No Charge",
  ONETIME: "One time",
}

export enum FrequencyEnum {
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
}

export type CountrySRCMap = {
  country: string
  src: string
}

// type Frequency = {
//   WEEKLY: "Week"
//   MONTHLY: "Month"
//   QUARTERLY: "Quarter"
//   YEARLY: "Year"
// }

export enum ContractTypeEnum {
  BOOKABLE_SUBSCRIPTION = "BOOKABLE_SUBSCRIPTION",
  BOOKABLE_PERSESSION = "BOOKABLE_PERSESSION",
  BOOKABLE_INADVANCE = "BOOKABLE_INADVANCE",
  BOOKABLE_NOCHARGE = "BOOKABLE_NOCHARGE",
  SCHEDULED_SUBSCRIPTION = "SCHEDULED_SUBSCRIPTION",
  SCHEDULED_PERSESSION = "SCHEDULED_PERSESSION",
  SCHEDULED_INADVANCE = "SCHEDULED_INADVANCE",
  SUBSCRIPTION = "SUBSCRIPTION",
  PRODUCT_ONETIME = "PRODUCT_ONETIME",
  PRODUCT_SUBSCRIPTION = "PRODUCT_SUBSCRIPTION",
  CUSTOMER = "CUSTOMER",
  TRIAL = "TRIAL",
}

export const ContractType = {
  BOOKABLE_SUBSCRIPTION: "Bookable Subscription",
  BOOKABLE_PERSESSION: "Bookable per session",
  BOOKABLE_INADVANCE: "Bookable in advance",
  BOOKABLE_NOCHARGE: "Bookable no charge",
  SCHEDULED_SUBSCRIPTION: "Scheduled Subscription",
  SCHEDULED_PERSESSION: "Scheduled per session",
  SCHEDULED_INADVANCE: "Scheduled in advance",
  SUBSCRIPTION: "Subscription",
  CUSTOMER: "Customer",
  TRIAL: "Trial",
}
export interface StripeProduct {
  id?: string
  object?: string
  active?: boolean
  created?: number
  description?: string
  images?: [string]
  livemode?: boolean
  metadata?: any
  name?: string
  package_dimensions?: any
  shippable?: any
  statement_descriptor?: string
  tax_code?: any
  unit_label?: any
  updated?: number
  url?: string
}

export interface StripePrice {
  id?: string
  object?: string
  active?: boolean
  billing_scheme?: string
  created?: number
  currency?: string
  livemode?: boolean
  lookup_key?: string
  metadata?: any
  nickname?: string
  product?: string
  recurring?: {
    aggregate_usage?: any
    interval?: string
    interval_count?: number
    usage_type?: string
  }
  tax_behavior?: string
  tiers_mode?: any
  transform_quantity?: any
  type?: string
  unit_amount?: number
  unit_amount_decimal?: string
}
export interface PriceTier {
  name?: string
  amount: number
  maxQuantity: number
  shareable: boolean
  shareableQuantity?: number
}
export enum PaymentModel {
  NONE = "NONE",
  FULL = "FULL",
  DEPOSIT = "DEPOSIT",
}

export enum ContentType {
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
  POST = "POST",
  FILE = "FILE",
  LINK = "LINK",
  HTML = "HTML",
  AFFILIATE = "AFFILIATE",
}

export enum DepositMethod {
  AMOUNT = "AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}

export enum PriceFrequency {
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
}

export enum PriceType {
  ONETIME = "ONETIME",
  RECURRING = "RECURRING",
  HOURLY = "HOURLY",
}

export enum PriceScheme {
  PERUNIT = "PERUNIT",
  TIERED = "TIERED",
}

export interface Price {
  id: string
  active: boolean
  currency: string
  name: string
  paymentRef?: string
  productRef: string
  paymentModel: PaymentModel
  frequency?: PriceFrequency
  scheme: PriceScheme
  type: PriceType
  depositMethod?: DepositMethod
  depositAmount?: number
  depositPercentage?: number
  hourlyRate?: number
  hours?: number
  amount: number
  tiers?: PriceTier[]
  organizationID?: string
  baseType: string
  createdAt?: string
  updatedAt?: string
}

export interface BookingRestrictions {
  id: string
  nextAvailableDate: string
  lastFrequencyDate?: string
  frequencySlotsAvailable?: number
  totalSlotsAvailable?: number
  contractID: string
  serviceID: string
  organizationID: string
  baseType: string
  createdAt?: string
  updatedAt?: string
}

export interface ServiceBookingRestrictions {
  capacity?: number
  totalSlots?: number
  frequency?: number
  frequencyPeriod?: FrequencyEnum
}

export enum AffiliateProductType {
  INTERNAL = "INTERNAL",
  BUSINESSOWNER = "BUSINESSOWNER",
}
export interface AffiliateProduct {
  id: string
  organizationID: string
  contractIDs?: string[]
  serviceIDs?: string[]
  categoryIDs?: string[]
  uploadedBy?: string
  countrySourceMap?: [CountrySRCMap]
  src: string
  type: AffiliateProductType
  mimeType?: string
  metrics?: any
  title: string
  subtitle?: string
  description?: string
  thumbnail?: string
}

export interface AffiliateLink {
  id: string
  affiliateProductID: string
  azingoAffiliateProduct: AffiliateProduct
  organizationID: string
  baseType: string
  metrics?: any
  createdAt?: string
  updatedAt?: string
}

export interface Category {
  id: string
  name: string
  displayOrder: number
  isDeleted: boolean
  organizationID: string
}

export const sampleCategories = [
  {
    id: "476de46b-976d-4d6b-bba7-674f0a3fe91a",
    name: "Services",
    displayOrder: 1,
    isDeleted: false,
    organizationID: "2ed44704-e51f-4cbc-89bd-cbc6a3b23b0b",
    affiliateLinkIDs: ["3baf57aa-f929-46bc-a160-7c9b9c07cf3a"],
    serviceIDs: ["9d67bdc5-e038-4844-8c0d-70ee698779aa"],
    affiliateProductIDs: [],
  },
  {
    id: "0dad0df1-5437-4774-8747-1474ea06e1ff",
    name: "My Links",
    displayOrder: 2,
    isDeleted: false,
    organizationID: "2ed44704-e51f-4cbc-89bd-cbc6a3b23b0b",
    affiliateLinkIDs: ["3baf57aa-f929-46bc-a160-7c9b9c07cf3a"],
    serviceIDs: [],
    affiliateProductIDs: [
      "122db3d4-67c4-4915-9ba7-36bc2b3e8fda",
      "e2202318-e174-463f-8486-0bd650d37bc2",
      "2d52beba-b0eb-4cbe-8860-03ec858cb59c",
      "ac10578d-e8a2-49eb-bb8c-c7b55b5fda13",
      "91d95baa-2f72-43fd-b7b3-0bc1591c005c",
    ],
  },
  {
    id: "8f900a73-460a-48a7-b22b-85650fa0e3ea",
    name: "My Favourites",
    displayOrder: 3,
    isDeleted: false,
    organizationID: "2ed44704-e51f-4cbc-89bd-cbc6a3b23b0b",
    affiliateLinkIDs: ["3baf57aa-f929-46bc-a160-7c9b9c07cf3a"],
    serviceIDs: ["9d67bdc5-e038-4844-8c0d-70ee698779aa"],
    affiliateProductIDs: [
      "e2202318-e174-463f-8486-0bd650d37bc2",
      "91d95baa-2f72-43fd-b7b3-0bc1591c005c",
    ],
  },
  {
    id: "8a09c501-baeb-43f7-bae8-4426879ebc11",
    name: "Best Deals",
    displayOrder: 4,
    isDeleted: false,
    organizationID: "2ed44704-e51f-4cbc-89bd-cbc6a3b23b0b",
    affiliateLinkIDs: ["3baf57aa-f929-46bc-a160-7c9b9c07cf3a"],
    serviceIDs: ["9d67bdc5-e038-4844-8c0d-70ee698779aa"],
    affiliateProductIDs: [
      "ac10578d-e8a2-49eb-bb8c-c7b55b5fda13",
      "91d95baa-2f72-43fd-b7b3-0bc1591c005c",
    ],
  },
]

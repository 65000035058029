import { BookingTypeEnum } from "./Service"

export interface Recommendation {
  id: string
  organizationID: string
  contractIDs?: string[]
  serviceIDs?: string[]
  affiliateProductIDs?: string[]
  createdAt?: string
  updatedAt?: string
}

export interface RecoItem {
  id: string
  bookingType?: BookingTypeEnum
  name: string
  description?: string
  testimonial?: string
  thumbnail?: string
  type: RecoItemType
  url?: string
  createdAt?: string
  updatedAt?: string
}

export enum RecoItemType {
  SERVICE = "SERVICE",
  AFFILIATEPRODUCT = "AFFILIATEPRODUCT",
}

export const sampleRecommendation = {
  id: "123456789",
  organizationID: "2ed44704-e51f-4cbc-89bd-cbc6a3b23b0b",
  contractIDs: ["3baf57aa-f929-46bc-a160-7c9b9c07cf3a"],
  serviceIDs: ["9d67bdc5-e038-4844-8c0d-70ee698779aa"],
  affiliateProductIDs: [
    "122db3d4-67c4-4915-9ba7-36bc2b3e8fda",
    "e2202318-e174-463f-8486-0bd650d37bc2",
    "2d52beba-b0eb-4cbe-8860-03ec858cb59c",
    "ac10578d-e8a2-49eb-bb8c-c7b55b5fda13",
    "91d95baa-2f72-43fd-b7b3-0bc1591c005c",
  ],
  createdAt: "2023-12-21T19:07:22.491Z",
  updatedAt: "2023-12-21T19:07:22.491Z",
}

export enum LocationRestrictionType {
  GEODISTANCE = "GEODISTANCE",
  ADDRESSMATCH = "ADDRESSMATCH",
  ADDRESSBLOCK = "ADDRESSBLOCK",
}

export enum ServiceLocationType {
  CUSTOMERPREMESIS = "CUSTOMERPREMESIS",
  PROVIDERPREMESIS = "PROVIDERPREMESIS",
  FLEXIBLEPREMESIS = "FLEXIBLEPREMESIS",
}

export interface Location {
  name?: string
  formatted?: string
  street_address?: string
  locality?: string
  region?: string
  postal_code?: string
  country?: string
  geoPoint?: GeoPoint
  place_id?: string
}
export interface GeoPoint {
  longitude: number
  latitude: number
}
export interface LocationRestriction {
  type: LocationRestrictionType
  distanceMeters?: number
  addressReference?: string
  addressCriteria?: [string]
  customerReason?: string
}

export interface GeoPoint {
  longitude: number
  latitude: number
}

export interface LocationAttributes {
  name?: string
  formatted?: string
  street_address?: string
  locality?: string
  region?: string
  postal_code?: string
  country?: string
  geoPoint?: GeoPoint
  place_id?: string
}

export interface LocationRestriction {
  type: LocationRestrictionType
  distanceMeters?: number
  locationReference?: Location
  addressReference?: string
  addressCriteria?: [string]
  customerReason?: string
}

interface PlaceOffset {
  length: number
  offset: number
}

interface PlaceTerm {
  offset: number
  value: string
}

interface PlaceAddressComponents {
  long_name?: string
  short_name?: string
  types?: string[]
}

interface PlaceStructured {
  main_text?: string
  main_text_matched_substrings?: PlaceOffset[]
  secondary_text?: string
}

interface GeoLocation {
  location: {
    lat: number
    lng: number
  }
}

interface ViewPort {
  northeast?: GeoLocation
  southwest?: Geolocation
}
export interface PlaceObject {
  description?: string
  matched_substrings?: PlaceOffset[]
  place_id?: string
  reference?: string
  structured_formatting?: PlaceStructured
  terms?: PlaceTerm[]
  types?: string[]
}

export interface PlaceDetailObject {
  address_components?: PlaceAddressComponents[]
  addr_address?: string
  formatted_address?: string
  geometry?: GeoLocation
  viewPort?: ViewPort
  icon?: string
  icon_background_color?: string
  icon_mask_base_uri?: string
  name?: string
  place_id?: string
  reference?: string
  types?: string[]
  url?: string
  utc_offset?: number
  vicinity?: string
}

export interface LocationList extends Array<LocationAttributes> {}

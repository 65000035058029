import React, { createContext, useContext, useEffect, useState } from "react"
import { Amplify, API, Auth } from "aws-amplify"
import awsconfig from "../aws-exports"
import { GraphQLOptions } from "@aws-amplify/api-graphql"
import { purchaseSessionByOrgAndUser, listTimeSlots } from "../graphql/queries"
import {
  createPurchaseSession,
  updatePurchaseSession,
  deletePurchaseSession,
  // azingoPurchaseSessionCleanup,
} from "../graphql/mutations"
import { CalendarSlotType, PurchaseSession } from "../constants/Cart"
import { TimeSlot, TimeSlotInfo } from "../constants/TimeSlot"
import { BookingTypeEnum, Service } from "../constants/Service"
import { Location } from "../constants/Location"
import { AccountContext } from "../contexts/account-context"
import { useCookies } from "react-cookie"
import generateUuid from "../utils/generate-uuid"

Amplify.configure(awsconfig)

export enum CartStates {
  SCHEDULE = "SCHEDULE",
  LOCATION = "LOCATION",
  PHONE = "PHONE",
  TEAMMATE = "TEAMMATE",
  PRICING = "PRICING",
  PAYMENT = "PAYMENT",
  BOOKING = "BOOKING",
}

export interface SessionData {
  service?: Service
  timeSlotInfo?: TimeSlotInfo
  startDate?: string
  endDate?: string
  priceID?: string
  phoneNumber?: string
  purchased?: boolean
  location?: Location
  teamID?: string
  source?: string
  skip?: CartStates
  pop?: CartStates
}
interface CartState {
  cartStates: string[]
  clearCartStates: () => void
  purchaseSession?: PurchaseSession
  fetchPurchaseSession: (
    serviceId: string | undefined
  ) => Promise<PurchaseSession | undefined>
  refreshPurchaseSession: (
    sessionData: SessionData,
    serviceID: string
  ) => Promise<void>
  authenticatePurchaseSession: (
    sessionData: SessionData,
    serviceID: string
  ) => Promise<void>
  clearPurchaseSession: () => Promise<void>
  // purgePurchaseSessions: () => Promise<void>
  fetchScheduledTimeSlots: (serviceID: string) => Promise<TimeSlot[] | any[]>
  updatingPurchaseSession: boolean
  getDefaultTeamID: () => string | undefined
  getTeamIDs: () => string[]
}

interface CartContextProps {
  children: React.ReactNode
}

export const CartContext = createContext<CartState>({
  cartStates: [],
  clearCartStates: () => {},
  purchaseSession: undefined,
  fetchPurchaseSession: async () => undefined,
  refreshPurchaseSession: async () => {},
  authenticatePurchaseSession: async () => {},
  clearPurchaseSession: async () => {},
  // purgePurchaseSessions: async () => undefined,
  fetchScheduledTimeSlots: async () => [],
  updatingPurchaseSession: false,
  getDefaultTeamID: () => undefined,
  getTeamIDs: () => [],
})

const CartContextProvider = ({ children }: CartContextProps) => {
  const [purchaseSession, setPurchaseSession] = useState<PurchaseSession>()
  const { getServiceContracts } = useContext(AccountContext)
  const [updatingPurchaseSession, setUpdatingPurchaseSession] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [cartStates, setCartStates] = useState<string[]>([])
  const [cookies, setCookie, removeCookie] = useCookies(["cart"])
  const clearPurchaseSession = async () => {
    const deleteQuery = {
      query: deletePurchaseSession,
      variables: {
        input: {
          id: purchaseSession?.id,
        },
      },
    }
    try {
      await (API.graphql(deleteQuery) as Promise<any>)
      setPurchaseSession(undefined)
    } catch (error) {}
  }

  const clearCartStates = () => {
    setCartStates([])
  }

  // const purgePurchaseSessions = async () => {
  //   setUpdatingPurchaseSession(true)
  //   setCartStates([])
  //   let authMode: GraphQLOptions["authMode"]
  //   try {
  //     const cognitoUserResponse = await Auth.currentAuthenticatedUser()
  //     if (cognitoUserResponse) {
  //       authMode = "AMAZON_COGNITO_USER_POOLS"
  //     }
  //   } catch {
  //     authMode = "AWS_IAM"
  //   }
  //   const query = {
  //     query: azingoPurchaseSessionCleanup,
  //     variables: {
  //       organizationID: `ffcb4cc9-1e5a-4889-a70d-783f6619227d`,
  //     },
  //     authMode: authMode,
  //   }
  //   try {
  //     await (API.graphql(query) as Promise<any>)
  //     setPurchaseSession(undefined)
  //   } catch (error) {
  //     console.log("error purging PurchaseSessions", error)
  //   } finally {
  //     setUpdatingPurchaseSession(false)
  //   }
  // }

  const authenticatePurchaseSession = async (
    sessionData: SessionData,
    serviceID: string = ""
  ) => {
    let userID
    let anonymousUserID
    if (cookies.cart) {
      anonymousUserID = cookies.cart
      console.log("Setting UserID from Cookie", cookies.cart)
    }
    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()
      if (cognitoUserResponse) {
        userID = cognitoUserResponse.attributes.sub
      }
    } catch (error) {
      console.log("Unable to find authenticated user ", error)
    }

    const sessionExists = await fetchAnonymousPurchaseSession(
      sessionData?.service?.id || serviceID,
      anonymousUserID
    )
    console.log("Found this session using cookie value", sessionExists)
    if (sessionExists) {
      let updateInput: any = { id: sessionExists?.id, userID: userID }
      const updateQuery = {
        query: updatePurchaseSession,
        variables: {
          input: updateInput,
        },
      }

      console.log("authenticate Purchase session update query", updateQuery)
      try {
        const {
          data: { updatePurchaseSession: response },
        } = await (API.graphql(updateQuery) as Promise<any>)
        setPurchaseSession(response)
        console.log("Authenticated purchase session response", response)
      } catch (error) {
        console.log("Unable to authenticate PurchaseSession", error)
      }
    } else {
      console.log("Purchase session does not exist", sessionData)
    }
  }

  const skipPurchaseState = (stateToSkip: CartStates) => {
    if (stateToSkip) {
      switch (stateToSkip) {
        case CartStates.PRICING:
          stateToSkip = CartStates.PRICING
          break
        case CartStates.BOOKING:
          stateToSkip = CartStates.BOOKING
          break
        case CartStates.LOCATION:
          stateToSkip = CartStates.LOCATION
          break
        case CartStates.SCHEDULE:
          stateToSkip = CartStates.SCHEDULE
          break
        case CartStates.TEAMMATE:
          stateToSkip = CartStates.TEAMMATE
          break
        case CartStates.PHONE:
          stateToSkip = CartStates.PHONE
          break
        case CartStates.PAYMENT:
          stateToSkip = CartStates.PAYMENT
          break
        default:
          stateToSkip = CartStates.PRICING
      }
      setCartStates(currentArray => {
        return [...new Set([...currentArray, ...[stateToSkip]])]
      })
      return
    }
  }

  const popPurchaseState = (stateToPop: CartStates) => {
    if (stateToPop) {
      switch (stateToPop) {
        case CartStates.PRICING:
          stateToPop = CartStates.PRICING
          break
        case CartStates.BOOKING:
          stateToPop = CartStates.BOOKING
          break
        case CartStates.LOCATION:
          stateToPop = CartStates.LOCATION
          break
        case CartStates.SCHEDULE:
          stateToPop = CartStates.SCHEDULE
          break
        case CartStates.TEAMMATE:
          stateToPop = CartStates.TEAMMATE
          break
        case CartStates.PHONE:
          stateToPop = CartStates.PHONE
          break
        case CartStates.PAYMENT:
          stateToPop = CartStates.PAYMENT
          break
        default:
          stateToPop = CartStates.PRICING
      }
      setCartStates(currentArray =>
        currentArray.filter(state => state !== stateToPop)
      )
      return
    }
  }
  const refreshPurchaseSession = async (
    sessionData: SessionData,
    serviceID: string = ""
  ) => {
    console.log("REFRESH PURCHASE SESSION SOURCE", sessionData?.source)
    console.log("REFRESH PURCHASE SESSION DATA", sessionData)
    console.log("REFRESH COUNTER", refreshCounter)
    if (refreshCounter > 1000) return
    let userID
    let timeSlotArray: any[] = []
    let authMode: GraphQLOptions["authMode"]
    if (sessionData?.skip) {
      skipPurchaseState(sessionData.skip)
    }

    if (sessionData?.pop) {
      popPurchaseState(sessionData.pop)
    }

    setUpdatingPurchaseSession(true)

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()
      if (cognitoUserResponse) {
        userID = cognitoUserResponse.attributes.sub
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
      removeCookie("cart")
    } catch {
      authMode = "AWS_IAM"
      if (cookies.cart) {
        userID = cookies.cart
      } else {
        userID = generateUuid()
        setCookie("cart", userID, { path: "/" })
      }
    }

    if (sessionData?.service?.bookingType === BookingTypeEnum?.SCHEDULED) {
      const scheduledTimeSlotArray = await fetchScheduledTimeSlots(
        serviceID || sessionData?.service?.id || ""
      )
      timeSlotArray = Array.from(
        scheduledTimeSlotArray,
        timeSlot => timeSlot.id
      )
    }

    const purchaseStatus = await validContractExists(
      sessionData?.service?.id || serviceID
    )
    console.log("purchase status:", purchaseStatus)
    if (purchaseStatus) {
      setCartStates(currentArray => {
        return [...new Set([...currentArray, ...[CartStates.PAYMENT]])]
      })
    }
    const sessionExists = await fetchPurchaseSession(
      sessionData?.service?.id || serviceID
    )
    console.log("REFRESH PURCHASE SESSION sessionExists?", sessionExists)
    if (sessionExists) {
      console.log("REFRESH PURCHASE SESSION session Exists", sessionExists)
      let updateInput: any = { id: sessionExists?.id }
      if (sessionData?.service) {
        updateInput["serviceID"] = sessionData?.service?.id || serviceID
        if (sessionData?.purchased) {
          updateInput["purchased"] = sessionData?.purchased || false
        }
      }
      if (timeSlotArray?.length > 0) {
        updateInput["scheduledTimeSlotIDs"] = timeSlotArray
      }
      if (sessionData?.timeSlotInfo) {
        if (sessionData?.timeSlotInfo?.teamAvailabilityID) {
          updateInput["teamAvailabilityID"] =
            sessionData?.timeSlotInfo?.teamAvailabilityID
          updateInput["timeSlotID"] = undefined
          updateInput["calendarSlotType"] = CalendarSlotType.TEAMAVAILABILITY
        } else if (sessionData?.timeSlotInfo?.timeSlotID) {
          updateInput["timeSlotID"] = sessionData?.timeSlotInfo?.timeSlotID
          updateInput["teamAvailabilityID"] = undefined
          updateInput["calendarSlotType"] = CalendarSlotType.TIMESLOT
        }
        updateInput["startDate"] =
          sessionData?.startDate || sessionData?.timeSlotInfo?.startDate
        updateInput["endDate"] =
          sessionData?.endDate || sessionData?.timeSlotInfo?.endDate
        updateInput["teamID"] = sessionData?.timeSlotInfo?.teamID
        setCartStates(currentArray => {
          return [...new Set([...currentArray, ...[CartStates.SCHEDULE]])]
        })
      }
      if (sessionData?.teamID) {
        updateInput["teamID"] = sessionData?.teamID
        setCartStates(currentArray => {
          return [...new Set([...currentArray, ...[CartStates.TEAMMATE]])]
        })
      }
      if (sessionData?.priceID) {
        updateInput["priceID"] = sessionData?.priceID
        console.log("priceUpdated")
        setCartStates(currentArray => {
          return [...new Set([...currentArray, ...[CartStates.PRICING]])]
        })
      }
      if (sessionData?.phoneNumber) {
        updateInput["phoneNumber"] = sessionData?.phoneNumber
        console.log("phoneUpdated")
        setCartStates(currentArray => {
          return [...new Set([...currentArray, ...[CartStates.PHONE]])]
        })
      }
      if (sessionData?.location) {
        // console.log("updating location to:", sessionData?.location)
        // console.log("from Session Data:", sessionData)
        updateInput["location"] = sessionData?.location
        setCartStates(currentArray => {
          return [...new Set([...currentArray, ...[CartStates.LOCATION]])]
        })
      }
      try {
        console.log("updatePurchaseSession updateInput", updateInput)
        const updateQuery = {
          query: updatePurchaseSession,
          variables: {
            input: updateInput,
          },
          authMode: authMode,
        }
        console.log("About to update Session", sessionExists)
        const {
          data: { updatePurchaseSession: response },
        } = await (API.graphql(updateQuery) as Promise<any>)
        response["purchased"] = purchaseStatus
        if (response?.service?.bookingType === BookingTypeEnum.PRODUCT) {
          setCartStates(currentArray => {
            return [
              ...new Set([
                ...currentArray,
                ...[
                  CartStates.LOCATION,
                  CartStates.SCHEDULE,
                  CartStates.TEAMMATE,
                ],
              ]),
            ]
          })
        }
        setPurchaseSession(response)
        console.log("session updated result", response)
      } catch (err) {
        console.log("Error updating purchase session", err)
      }
    } else {
      console.log(
        "REFRESH PURCHASE SESSION session DOES NOT exist",
        sessionData
      )

      try {
        const createQuery = {
          query: createPurchaseSession,
          variables: {
            input: {
              organizationID: `ffcb4cc9-1e5a-4889-a70d-783f6619227d`,
              userID: userID,
              serviceID: sessionData?.service?.id || serviceID,
              timeSlotID: sessionData?.timeSlotInfo?.timeSlotID,
              teamAvailabilityID: sessionData?.timeSlotInfo?.teamAvailabilityID,
              teamID: sessionData?.teamID || sessionData?.timeSlotInfo?.teamID,
              scheduledTimeSlotIDs: timeSlotArray,
              priceID: sessionData?.priceID,
              location: sessionData?.location,
              purchased: sessionData?.purchased || false,
              startDate: sessionData?.timeSlotInfo?.startDate,
              endDate: sessionData?.timeSlotInfo?.endDate,
              baseType: "PurchaseSession",
            },
          },
          authMode: authMode,
        }

        console.log("About to create Session", createQuery)
        const {
          data: { createPurchaseSession: response },
        } = await (API.graphql(createQuery) as Promise<any>)
        response["purchased"] = purchaseStatus
        setPurchaseSession(response)
        console.log("Newly created Session:", response)
      } catch (error) {
        console.log("error creating purchase session", error)
      } finally {
        setRefreshCounter(prev => prev + 1)
        setUpdatingPurchaseSession(false)
      }
    }
  }

  const fetchScheduledTimeSlots = async (serviceID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      let nextToken = null
      let items: TimeSlot[] = []
      do {
        const query: any = await API.graphql({
          query: listTimeSlots,
          variables: {
            organizationID: { eq: `ffcb4cc9-1e5a-4889-a70d-783f6619227d` },
            filter: {
              serviceID: {
                eq: serviceID,
              },
            },
            nextToken,
          },
          authMode: authMode,
        })

        items = items.concat(query?.data?.listTimeSlots?.items)
        nextToken = query?.data?.listTimeSlots?.nextToken
      } while (nextToken)
      return items
    } catch (err) {
      return []
    }
  }

  const validContractExists = async (serviceID: string) => {
    const contractList = await getServiceContracts(serviceID)
    const validContracts = contractList.filter(
      contract => contract.active && contract.status === "PAID"
    )
    if (validContracts && validContracts.length > 0) {
      // console.log("valid contracts:", validContracts)
      return true
    } else {
      return false
    }
  }

  const fetchAnonymousPurchaseSession = async (
    serviceID: string | undefined,
    userID: string
  ) => {
    let authMode: GraphQLOptions["authMode"]
    let anonymousUserID = userID
    if (cookies.cart && !anonymousUserID) {
      anonymousUserID = cookies.cart
      console.log("Setting anonymousUserID from Cookie", cookies.cart)
    } else {
      console.log("Could not find userID in cookies")
    }

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()
      if (cognitoUserResponse) {
        // userID = cognitoUserResponse.attributes.sub
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
      console.log("fetchAnonymousPurchaseSession cookies.cart", cookies.cart)
      removeCookie("cart")
    } catch (err) {
      console.log("attempting to fetch Purchase sessions anonymously", err)
    }

    try {
      let nextToken = null
      let sessions: PurchaseSession[] = []
      if (anonymousUserID) {
        do {
          const result: any = await API.graphql({
            query: purchaseSessionByOrgAndUser,
            variables: {
              organizationID: `ffcb4cc9-1e5a-4889-a70d-783f6619227d`,
              userID: { eq: anonymousUserID },
              filter: { serviceID: { eq: serviceID } },
              nextToken,
            },
            authMode: authMode,
          })
          console.log("fetch Anonymous Session Query result", result?.data)
          console.log(
            "fetch Anonymous  session anonymousUserID",
            anonymousUserID
          )
          console.log("fetch Anonymous  session serviceID", serviceID)
          // @ts-ignore
          sessions = sessions.concat(
            result?.data.purchaseSessionByOrgAndUser?.items
          )
          // @ts-ignore
          nextToken = result?.data.purchaseSessionByOrgAndUser?.nextToken
        } while (nextToken)
      }
      console.log("Anonymous serviceID", serviceID)
      console.log("Anonymous sessions", sessions)
      if (serviceID) {
        if (sessions && sessions.length > 1) {
          const currentSession = sessions.find(
            session => session.serviceID === serviceID
          )
          if (currentSession) {
            console.log("Anonymous currentSession", currentSession)

            return currentSession
          }
        } else if (sessions && sessions.length === 1) {
          return sessions?.[0]
        }
      }
      console.log(
        "Fetch Anonymous session fell all the way through and returned undefined"
      )
      return undefined
    } catch (error) {
      console.log("Anonymous Fetch session Query error", error)
    }
  }

  const fetchPurchaseSession = async (serviceID: string | undefined) => {
    let userID
    let authMode: GraphQLOptions["authMode"]
    setUpdatingPurchaseSession(true)
    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()
      if (cognitoUserResponse) {
        userID = cognitoUserResponse.attributes.sub
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
      removeCookie("cart")
    } catch (err) {
      authMode = "AWS_IAM"
      if (cookies.cart) {
        userID = cookies.cart
        console.log(
          "FETCH PURCHASE SESSION Setting UserID from Cookie",
          cookies.cart
        )
      } else {
        console.log("REFRESH PURCHASE SESSION Cookie CANNOT be found!!")
        setPurchaseSession(undefined)
        return undefined
      }
    }
    try {
      let nextToken = null
      let sessions: PurchaseSession[] = []
      do {
        const result: any = await API.graphql({
          query: purchaseSessionByOrgAndUser,
          variables: {
            organizationID: `ffcb4cc9-1e5a-4889-a70d-783f6619227d`,
            userID: { eq: userID },
            filter: { serviceID: { eq: serviceID } },
            nextToken,
          },
          authMode: authMode,
        })
        console.log("fetch Session Query result", result?.data)
        console.log("fetch session userID", userID)
        console.log("fetch session serviceID", serviceID)
        // @ts-ignore
        sessions = sessions.concat(
          result?.data.purchaseSessionByOrgAndUser?.items
        )
        // @ts-ignore
        nextToken = result?.data.purchaseSessionByOrgAndUser?.nextToken
      } while (nextToken)
      console.log("serviceID", serviceID)
      console.log("sessions", sessions)
      if (serviceID) {
        if (sessions && sessions.length > 1) {
          const currentSession = sessions.find(
            session => session.serviceID === serviceID
          )
          if (currentSession) {
            console.log("currentSession", currentSession)
            setPurchaseSession(currentSession)
            return currentSession
          } else {
            setPurchaseSession(undefined)
          }
        } else if (sessions) {
          setPurchaseSession(sessions?.[0])
          return sessions?.[0]
        }
      }
      setPurchaseSession(sessions?.[0])
      return sessions?.[0]
    } catch (error) {
      console.log("Fetch session Query error", error)
    } finally {
      setUpdatingPurchaseSession(false)
    }
  }

  const getDefaultTeamID = () => {
    if (purchaseSession?.teamID) {
      return purchaseSession.teamID
    } else if (purchaseSession && !purchaseSession.teamID) {
      if (purchaseSession.teamAvailabilityID) {
        if (
          purchaseSession.teamAvailability?.teamIDs &&
          purchaseSession.teamAvailability?.teamIDs?.length > 0
        ) {
          return purchaseSession.teamAvailability?.teamIDs?.[0]
        } else if (purchaseSession.teamAvailability?.teamIDs?.length === 1) {
          return purchaseSession.teamAvailability?.teamIDs?.[0]
        }
      } else if (purchaseSession.timeSlotID) {
        if (purchaseSession.timeSlot?.teamID) {
          return purchaseSession.timeSlot?.teamID
        }
      } else {
        return purchaseSession?.service?.teamIDs?.[0] || ""
      }
    } else {
      return undefined
    }
  }

  const getTeamIDs = () => {
    if (purchaseSession) {
      if (purchaseSession.teamAvailabilityID) {
        if (
          purchaseSession.teamAvailability?.teamIDs &&
          purchaseSession.teamAvailability?.teamIDs?.length > 0
        ) {
          return purchaseSession.teamAvailability.teamIDs || []
        }
      } else if (purchaseSession.timeSlotID) {
        if (purchaseSession.timeSlot?.teamID) {
          return [purchaseSession.timeSlot.teamID]
        }
      } else {
        return purchaseSession?.service?.teamIDs || []
      }
    } else {
      return []
    }
    return []
  }

  useEffect(() => {
    const getInitialPurchaseSession = async () => {
      await fetchPurchaseSession(undefined)
    }
    getInitialPurchaseSession()
  }, [])

  return (
    <CartContext.Provider
      value={{
        cartStates,
        clearCartStates,
        purchaseSession,
        fetchPurchaseSession,
        refreshPurchaseSession,
        authenticatePurchaseSession,
        clearPurchaseSession,
        // purgePurchaseSessions,
        fetchScheduledTimeSlots,
        updatingPurchaseSession,
        getDefaultTeamID,
        getTeamIDs,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartContextProvider

import { TimeSlot } from "./TimeSlot"
import { Service } from "./Service"
import { TeamAvailability } from "./TeamAvailability"
import { Location } from "./Location"

export enum CalendarSlotType {
  TIMESLOT = "TIMESLOT",
  TEAMAVAILABILITY = "TEAMAVAILABILITY",
}

export interface PurchaseSession {
  id: string
  organizationID: string
  userID: string
  serviceID?: string
  service?: Service
  startDate?: string
  endDate?: string
  teamID?: string
  timeSlotID?: string
  timeSlot?: TimeSlot
  priceID?: string
  phoneNumber?: string
  scheduledTimeSlotIDs?: string[]
  teamAvailabilityID?: string
  teamAvailability?: TeamAvailability
  location?: Location
  purchased: boolean
  calendarSlotType: CalendarSlotType
  baseType: string
}

import { TimeSlot } from "./TimeSlot"
import { Service, PriceType, PriceFrequency } from "./Service"
import { Contract } from "./Contract"
import { Location } from "./Location"

export enum BookingTypeEnum {
  UNREGISTERED = "UNREGISTERED",
  PENDING_CONTRACT = "PENDING_CONTRACT",
  PENDING_TEAM = "PENDING_TEAM",
  WAITLIST = "WAITLIST",
  CONFIRMED_UNPAID = "CONFIRMED_UNPAID",
  CONFIRMED_PAID = "CONFIRMED_PAID",
  CANCELED_CONTRACT = "CANCELED_CONTRACT",
  CANCELED_TEAM = "CANCELED_TEAM",
}

export enum ContractStatus {
  PAID = "PAID",
  INVOICED = "INVOICED",
  UNPAID = "UNPAID",
  QUOTED = "QUOTED",
  NOCHARGE = "NOCHARGE",
  ABANDONED = "ABANDONED",
  UNSUBSCRIBED = "UNSUBSCRIBED",
}

export enum BookingFailureReason {
  INVALID_LOCATION = "Confirm the location for this service.",
  INVALID_SCHEDULE = "Confirm the chosen schedule for this service",
  INVALID_TEAM = "Confirm the service team for this service",
  INVALID_PAYMENT = "Complete payment setup",
  ALREADY_REQUESTED = "It looks like you have already requested this.",
  ALREADY_BOOKED = "It looks like you have already booked this.",
}

export const BookingType = {
  UNREGISTERED: "Pending Subscriber's registration",
  PENDING_CONTRACT: "Pending Subscriber's confirmation",
  PENDING_TEAM: "Pending Team member's confirmation",
  WAITLIST: "In Waitlist",
  CONFIRMED_UNPAID: "Pending payment",
  CONFIRMED_PAID: "Confirmed",
  CANCELED_CONTRACT: "Canceled by Subscriber",
  CANCELED_TEAM: "Canceled by Team member",
}

export interface Booking {
  baseType: string
  createdAt: string
  bookingDate?: string
  id: string
  organizationID: string
  contractID: string
  timeSlotID: string
  serviceID: string
  location?: Location
  timeSlot?: TimeSlot
  contract?: Contract
  email: string
  type: string
  updatedAt: string
}

export interface PurchaseDisplay {
  serviceName: string
  serviceDescription?: string
  serviceUrl: string
  serviceHandle: string
  purchaseDate: string
  purchaseAmount: number
  purchaseReference: string
  booking?: BookingDisplay[]
  contractID?: string
  type?: PriceType
  status?: ContractStatus
  frequency?: PriceFrequency
  active?: boolean
  stripeCustomerID?: string
  stripePaymentIntentID?: string
  stripeSubscriptionID?: string
}

export interface BookingDisplay {
  bookingID: string
  bookingDate?: string
  serviceName?: string
  scheduledDate: string
  scheduledEndDate?: string
  location?: Location
  scheduledTime: string
  bookingStatus: string
  timeSlotID: string
}

export enum BookingResults {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  PURCHASE = "PURCHASE",
}

export interface BookingResponse {
  bookingResult?: BookingResults
  booking?: Booking
  bookingService?: Service
}

export interface RegistrationProps {
  serviceID: string
  timeSlotID?: string
  email: string
  locations?: string
  teamID?: string
}

import { createTheme } from "@mui/material/styles"

let themeColor = "#000"
let contrastColor = "#FFF"

if(`${process.env.THEME}` === "light") {
  themeColor = "#000"
  contrastColor = "#FFF"
} else if (`${process.env.THEME}` === "dark") {
  themeColor = "#FFF"
  contrastColor = "#000"
}
const defaultTheme = {
    components: {
      // MuiDatePicker: {
      //   styleOverrides: {
      //     root: {
      //       color: "#000",
      //       "&.Mui-focused": {
      //         color: "green",
      //       },
      //     },
      //   },
      // },
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundColor: "#2196F3",
            "&:hover": {
              backgroundColor: "#03DAC6" ,
            },
            color: "#fff"
          },
          outlined: {
            border: " 2px solid #03DAC6"
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }
        }
      },
      MuiCardActions: {
        styleOverrides: {
          marginTop: "auto"
        }
      },
      MuiSwitch: {
        styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#ccc"
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#0062cc"
          }
        },
        colorSecondary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#f2ff00"
          }
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: "#ccc",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#0062cc"
          }
        }
      }
      }
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      subtitle1: {
        color: contrastColor,
      },
    },
    palette: {
      mode: `${process.env.THEME}`,
      primary: {
        main: "#ffffff",
        light: "#ffffff",
        dark: "#000000",
        contrastText: contrastColor,
      },
     secondary: {
          main: "#ffffff",
          light: "#ffffff",
          dark: "#000000",
          contrastText:  contrastColor,
        },
      error: {
        main: "#f44336",
        dark: "#d32f2f",
        light: "#e57373",
      },
      background: {
        default: themeColor,
      //   default: "#000"
      },
      text: {
        primary: contrastColor,
        secondary: "#7D7987",
        disabled: "#CCC",
        buttonText: "#fff" //"#499fa4"
      },
      success: {
        main: "#4caf50",
        light: "#c8e6c9",
        dark: "#1b5e20",
        contrastText: "#fff",
      },
      info: {
        main: "#29b6f6",
        light: "#4fc3f7",
        dark: "#0288d1",
        contrastText: "#fff",
      },
      warning: {
        main: "#ffa726",
        light: "#ffb74d",
        dark: "#f57c00",
        contrastText: "#fff",
      },
    },
    shape: {
      borderRadius: 15,
    },
  }

const theme = createTheme(defaultTheme)

export default theme
const stripTypename = (value) => {
    if (Array.isArray(value)) {
      return value.map(stripTypename)
    } else if (value !== null && typeof (value) === "object") {
      const newObject = {}
      for (const property in value) {
        if (property !== '__typename') {
          newObject[property] = stripTypename(value[property])
        }
      }
      return newObject
    } else {
      return value
    }
  }
  
  export default stripTypename